.pageContainer {
  min-height: 100vh;
  background-color: #f9fafb;
  padding: 2rem;
}

.assignMeterContainer {
  max-width: 56rem;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.headerSection {
  height: 8rem;
  background-color: #0ea5e9;
  padding: 2rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.signatureContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f1f5f9;
  border-top: 1px solid #e2e8f0;
}

.signatureSection {
  width: 48%;
}
.logoContainer {
  width: 150px;
  height: 50px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.headerTitle {
  font-size: 2.25rem;
  font-weight: 600;
  color: #e2e8f0;
  text-transform: uppercase;
  margin: 0;
}

.formSection {
  padding: 2rem;
  background-color: white;
}

.deliveryDetails {
  margin-bottom: 2rem;
}

.metersSection {
  margin-bottom: 2rem;
}

.formInput {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #cbd5e1;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  background-color: white;
}
.footerSection {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem;
  background-color: #f1f5f9;
  border-top: 1px solid #e2e8f0;
}

.downloadButton {
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 500;
}

.nextButton {
  background-color: #0ea5e9;
  color: white;
  border: none;
  transition: background-color 0.2s;
}

.nextButton:hover {
  background-color: #0284c7;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .pageContainer {
    padding: 1rem;
  }

  .headerSection {
    height: auto;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

}

/* Print Styles */
@media print {
  .pageContainer {
    background-color: white;
    padding: 0;
  }

  .assignMeterContainer {
    box-shadow: none;
  }

  .downloadButton {
    display: none;
  }
}